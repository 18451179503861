<template>
  <div class="anniuncement">
    <a-table :columns="columns" :data-source="data" :expandRowByClick="true">
      <p slot="expandedRowRender" slot-scope="record" style="margin-left: -20px" >{{ record.description }}</p>
    </a-table>
    <div class="mind-null" v-if="data.leagth == 0">
      <p>
        <img src="../../assets/img/user/notice1135.png" alt="">
      </p>
        暂无导图 快去创建吧!
    </div>
  </div>
</template>
<script>
  const columns = [
    {
      title: "标题",
      dataIndex: "titel",
      key: "titel",
    },
    {
      title: "发件人",
      dataIndex: "name",
      key: "name",
      width: "16%",
    },
    {
      title: "发布时间",
      dataIndex: "dataTime",
      width: "16%",
      key: "dataTime",
    },
  ];

const data = [
  // {
  //   key: 1,
  //   titel: "【通知】《欢迎使用Mind+》",
  //   name: "vivo开放平台",
  //   dataTime: "2020-09-10",
  //   description:
  //     "My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.",
  // },
  // {
  //   key: 2,
  //   titel: "【通知】关于上传隐私政策文档通知",
  //   name: "vivo开放平台",
  //   dataTime: "2020-09-10",
  //   description:
  //     "在有上一级节点的对象，长按对象，出现辅助线时，可以移动到其他对象上关于上传隐私关于上传隐私政策文档通知在有上一级节点的对象，长按对象，出现辅助线时，可以移动到其他对象上关上传隐私政策文档通知在有上一级节点的对象，长按对象，出现辅助线时，可以移动到其他对象上",
  // },
  // {
  //   key: 3,
  //   titel: "【通知】关于上传隐私政策文档通知",
  //   name: "vivo开放平台",
  //   dataTime: "2020-09-10",
  //   description:
  //     "在有上一级节点的对象，长按对象，出现辅助线时，可以移动到其他对象上关于上传隐私关于上传隐私政策文档通知在有上一级节点的对象，长按对象，出现辅助线时，可以移动到其他对象上关上传隐私政策文档通知在有上一级节点的对象，长按对象，出现辅助线时，可以移动到其他对象上",
  // },
  // {
  //   key: 4,
  //   titel: "【通知】关于上传App隐私政策文档的通知",
  //   name: "vivo开放平台",
  //   dataTime: "2020-09-10",
  // },
  // {
  //   key: 5,
  //   titel: "【通知】关于上传App隐私政策文档的通知",
  //   name: "vivo开放平台",
  //   dataTime: "2020-09-10",
  // },
  // {
  //   key: 6,
  //   titel: "【通知】关于上传App隐私政策文档的通知",
  //   name: "vivo开放平台",
  //   dataTime: "2020-09-10",
  // },
  // {
  //   key: 7,
  //   titel: "【通知】关于上传App隐私政策文档的通知",
  //   name: "vivo开放平台",
  //   dataTime: "2020-09-10",
  // },
  // {
  //   key: 8,
  //   titel: "【通知】关于上传App隐私政策文档的通知",
  //   name: "vivo开放平台",
  //   dataTime: "2020-09-10",
  // },
  // {
  //   key: 9,
  //   titel: "【通知】关于上传App隐私政策文档的通知",
  //   name: "vivo开放平台",
  //   dataTime: "2020-09-10",
  // },
  // {
  //   key: 10,
  //   titel: "【通知】关于上传App隐私政策文档的通知",
  //   name: "vivo开放平台",
  //   dataTime: "2020-09-10",
  // },
  // {
  //   key: 11,
  //   titel: "【通知】关于上传App隐私政策文档的通知",
  //   name: "vivo开放平台",
  //   dataTime: "2020-09-10",
  // },
  // {
  //   key: 12,
  //   titel: "【通知】关于上传App隐私政策文档的通知",
  //   name: "vivo开放平台",
  //   dataTime: "2020-09-10",
  // },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    //索引 内容
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    //状态 选中内容
    console.log(selected, selectedRows, changeRows);
  },
};

export default {
  data() {
    return {
      data,
      columns,
    };
  },
  created(){
    // message.postBroadcastPull(null,res=>{
    //   console.log(res)
    // },error=>{
    //   console.log(error)
    // })
  }
};
</script>

<style scoped>
  /* .ant-table-thead >tr >th{
    background-color:red !important;
  } */
  .anniuncement {
    padding: 15px;
  }
  .ant-table-row-expand-icon-cell .ant-table-row-expand-icon{
      display: none !important;
      
  }
  .mind-null{
  /* height: 100px; */
  padding-top: 7em;
  color: #ccc;
  padding-bottom: 40px;
  text-align: center;
  /* line-height: 100px; */
  font-size: 18px; 
}
</style>